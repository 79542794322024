import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ImageSwapper() {
  const [nextImageIndex, setNextImageIndex] = useState(0);
  const [images, setImages] = useState([]); 
  const [mobileImages, setMobileImages] = useState([]); 
  const [isMobile, setIsMobile] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const imageUrl = 'https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.post('https://rentla.in/rentlacms/api/ve2/landingPage');
        const data = response.data;

        if (data.status && data.response_code === 1) {
          const webImages = data.Data.banner.map(banner => `${imageUrl}${banner.BannerImage}`);
          const mobileImages = data.Data.banner.map(banner => `${imageUrl}${banner.BannerAppImage}`);
          
          setImages(webImages);
          setMobileImages(mobileImages);
        } else {
          console.error('Failed to fetch images', data);
        }
      } catch (error) {
        
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        setNextImageIndex(prevIndex => (prevIndex + 1) % (isMobile ? mobileImages.length : images.length));
        setFadeOut(false);
      }, 900); 
    }, 5000); 

    return () => clearInterval(interval);
  }, [isMobile, images.length, mobileImages.length]);

  const getNextImage = () => {
    if (isMobile) {
      return mobileImages[nextImageIndex % (mobileImages.length || 1)];
    } else {
      return images[nextImageIndex % (images.length || 1)];
    }
  };

  return (
    <div className="image-container">
      <img
        src={getNextImage()}
        alt={`Image ${nextImageIndex + 1}`}
        className={`initial-image mt-5 ${fadeOut ? 'fade-out' : ''}`}
      />
    </div>
  );
}

export default ImageSwapper;
