import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Phone } from 'lucide-react'
// import callImage from '../public/call.png';

function Header() {

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
    let navigate = useNavigate();
    const [search, setSearch] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const enterKey = (e) => {
        if (e.key === 'Enter') {
            searchClicked()
        }
    }


    const searchClicked = () => {
        if (search === '') {

            return
        }
        else if (search === ' ') {
            console.log('sdf cs')
            setSearch('')
            return

        } else {

            localStorage.setItem('search', search)
            // navigate('/search')
            const categoryUrl = `https://rentla.in/search`;
            window.location.href = categoryUrl;
        }
    }


    return (
        <>
            <header className="header-area clearfix header-hm9 transparent-bar">
                <div className='head_containers'>
                    <div className="header-top-area">
                        <div className="row mx-0 mx-lg-5 py-3 ">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-12 col-xxl-4 d-flex align-items-center justify-content-between" style={{ width: "100%" }}>
                                <div style={{ width: '115px' }} className="logo-hm9 text-center">
                                    <Link href='/'>
                                        <img
                                            className='hdddimggg ms-0 ms-lg-4'
                                            width={115}
                                            height={50}
                                            alt="logo"
                                            src="https://rentla.in/static/media/lo.a4dd52b9e88ff269370c.png"
                                        />
                                    </Link>
                                </div>
                                <div className="header-right-wrap d-flex align-items-center">
                                    <div className="same-style header-search">
                                        <div className="search-container">
                                            <div className='d-flex justify-content-end align-items-center'>
                                                <div className="text-white py-2 px-3 rounded-lg flex items-center">
                                                    {/* <Phone style={{ marginRight: "10px" }} size={18} />
                                                    <span className="text-sm font-semibold">1-833-372-9071</span> */}

                                                    <a href="tel:+9940428882" className="d-inline-block">
                                                        <img
                                                            className='hdddimggg ms-0 ms-lg-4'
                                                            width={160}
                                                            height={65}
                                                            alt="logo"
                                                            src={`${process.env.PUBLIC_URL}/call.png`}
                                                        />
                                                    </a>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-8 col-md-8 col-sm-8 col-12 col-xxl-8">
                            </div> */}
                        </div>
                    </div>
                </div>
            </header>

        </>
    );
}

export default Header;
