import React from 'react';
import {useState,useEffect} from "react"


const imageUrl = `https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/`;

const CardComponent = ({ CategoryBannerImage,CategoryBannerTitle,CatId,CatName, title, description, buttonText, active, onClick,index}) => {

  const cardStyle = {
    borderRadius: "29px",
    backgroundColor: active ? "#161942" : "transparent",
    borderRadius: "29px",
    backgroundColor: active ? "#161942" : "transparent",
    borderColor: "#161942",
    borderWidth: "3px",
    minHeight: "200px", // 
    borderColor:"#161942",
    borderWidth:"3px"
  };

  const cardInnerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%", 
  };
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 600);
    }
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const cardTextStyle = {
    color: active ? "white" : "black",
    textAlign: "center",
    marginBottom: "10px",
    marginTop: "1px",
    fontSize: isMobile ? "19px" : "25px", 
  };
  

  const imageContainerStyle = {
    position: "relative", 
    height: "100%", 
  };

  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
    display: "block",
    margin: "0 auto",
    flexGrow: 1, 
    maxHeight: "80%", 
  };
  const buttonStyle = {
    top: "90px", 
    // right: "10px", 
  
    backgroundColor: "#FCB806",
    border: "none",
    borderRadius: "18px",
    // padding: "10px",
    // textAlign: "center",
  
    
  };
  
  function getImgStyle() {
    const isMobile = window.innerWidth <= 768;
    return {
      width: isMobile ? "180px" : "280px",
      height: isMobile ? "230px" : "230px"
    };
  }
  const handleRentNowClick = () => {
    const categoryUrl = `https://rentla.in/products/${encodeURIComponent(CatName).replace(/%20/g, '-')}/${CatId}/All/0`;
    window.location.href = categoryUrl;
  };
  return (
    <>

<div className="card mb-3 mx-2 " style={cardStyle} onClick={onClick}>
    <h4 className="card-title  pt-3 pt-lg-4 text-center "style={cardTextStyle}>{CategoryBannerTitle}</h4>
    <div className="card-body">
        <div className="d-lg-flex justify-between " style={{ justifyContent: "space-between" }}>
            <div className="text-center">
            <img src={`${imageUrl}${CategoryBannerImage}`} className="text-center img-fluid mx-auto d-block"  alt="No Image" />
            </div>
            <div className="text-center mt-0 mt-lg-5">
                <button className="btn btn-secondary mt-0 fw-semibold mt-lg-5 py-3 px-3 me-0 me-md-0 me-lg-3"  onClick={handleRentNowClick} style={buttonStyle}>Rent Now</button>
            </div>
        </div>
    </div>
</div>

    </>
  );
};

export default CardComponent;
